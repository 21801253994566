/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { saveUTMData } from "./src/utils"
import { CSRFProvider } from "./src/providers/CSRFProvider"

export const onRouteUpdate = () => {
  // window.scrollTo(0, 0);
}

export const onInitialClientRender = () => {
  console.log("ReactDOM.render has executed")
  saveUTMData()
}

export const wrapRootElement = ({ element }) => (
  <CSRFProvider>{element}</CSRFProvider>
)
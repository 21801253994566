import React from "react"

const CSRFContext = React.createContext()

export const CSRFProvider = ({ children }) => {
  const csrfToken = new Promise((resolve, reject) => {
    fetch("/api/csrf/", {
      headers: {
        "Content-Type": "application/json",
        "x-ik-csrf-internal-request-token":
          "lsdjlsdlfo2ekskndfn29839usdkfn23092!QW33@",
      },
    })
      .then(response => {
        if (response && response.status === 200) {
          return response.json()
        } else {
          return Promise.resolve({ token: "" })
        }
      })
      .then(({ token }) => {
        resolve(token)
      })
  })

  return (
    <CSRFContext.Provider
      value={{
        get: csrfToken,
      }}
    >
      {children}
    </CSRFContext.Provider>
  )
}

export const CSRFConsumer = CSRFContext.Consumer

export default CSRFContext

import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://7978a9aa9272d9a681b4aef52436b948@o1277188.ingest.us.sentry.io/4507746262450176",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 0,

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
